var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "pa-2 d-flex justify-content-between"
  }, [_c('v-btn', {
    attrs: {
      "width": "16em"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'SelectSession'
        });
      }
    }
  }, [_vm._v(" Go back to sessions list ")]), _c('v-btn', {
    on: {
      "click": function ($event) {
        _vm.empty_bin_dialog = true;
      }
    }
  }, [_vm._v(" Empty Recycle Bin ")])], 1)]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-data-table', {
    staticClass: "sessions-table mx-2 mb-4 flex-grow-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.trashed_sessions,
      "options": _vm.session_options,
      "loading": _vm.session_loading,
      "server-items-length": _vm.session_total,
      "footer-props": {
        showFirstLastPage: false,
        disableItemsPerPage: true,
        itemsPerPageOptions: [40]
      },
      "height": "80vh",
      "single-select": ""
    },
    on: {
      "update:options": function ($event) {
        _vm.session_options = $event;
      },
      "item-selected": _vm.onSelect,
      "click:row": _vm.onRowClick
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "float-right"
        }, [item.trashed ? _c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "dark": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-menu")])], 1)];
            }
          }], null, true),
          model: {
            value: item.isMenuOpen,
            callback: function ($$v) {
              _vm.$set(item, "isMenuOpen", $$v);
            },
            expression: "item.isMenuOpen"
          }
        }, [_c('v-list', [_c('v-dialog', {
          directives: [{
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.clickOutsideDialogSessionHideMenu,
            expression: "clickOutsideDialogSessionHideMenu"
          }],
          attrs: {
            "max-width": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on;
              return [_c('v-list-item', _vm._g({
                attrs: {
                  "link": ""
                }
              }, on), [_c('v-list-item-title', [_vm._v("Restore")])], 1)];
            }
          }], null, true),
          model: {
            value: _vm.restore_session_dialog,
            callback: function ($$v) {
              _vm.restore_session_dialog = $$v;
            },
            expression: "restore_session_dialog"
          }
        }, [_c('v-card', [_c('v-card-text', {
          staticClass: "pt-4"
        }, [_c('v-row', {
          staticClass: "m-0"
        }, [_c('v-col', {
          attrs: {
            "cols": "2"
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "green"
          }
        }, [_vm._v("mdi-undo-variant")])], 1), _c('v-col', {
          attrs: {
            "cols": "10"
          }
        }, [_c('p', [_vm._v(" Do you want to restore session "), _c('code', [_vm._v(_vm._s(item.id))]), _vm._v("? ")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.restore_session_dialog = false;
            }
          }
        }, [_vm._v(" No ")]), _c('v-btn', {
          attrs: {
            "color": "green darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.restore_session_dialog = false;

              _vm.restoreSession(item.id);
            }
          }
        }, [_vm._v(" Yes ")])], 1)], 1)], 1), _c('v-dialog', {
          directives: [{
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.clickOutsideDialogSessionHideMenu,
            expression: "clickOutsideDialogSessionHideMenu"
          }],
          attrs: {
            "max-width": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var on = _ref4.on;
              return [_c('v-list-item', _vm._g({
                attrs: {
                  "link": ""
                }
              }, on), [_c('v-list-item-title', [_vm._v("Delete permanently")])], 1)];
            }
          }], null, true),
          model: {
            value: _vm.remove_permanently_session_dialog,
            callback: function ($$v) {
              _vm.remove_permanently_session_dialog = $$v;
            },
            expression: "remove_permanently_session_dialog"
          }
        }, [_c('v-card', [_c('v-card-text', {
          staticClass: "pt-4"
        }, [_c('v-row', {
          staticClass: "m-0"
        }, [_c('v-col', {
          attrs: {
            "cols": "2"
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "red"
          }
        }, [_vm._v("mdi-close-circle")])], 1), _c('v-col', {
          attrs: {
            "cols": "10"
          }
        }, [_c('p', [_vm._v(" Do you want to "), _c('strong', [_vm._v("permanently")]), _vm._v(" remove session "), _c('code', [_vm._v(_vm._s(item.id))]), _vm._v("? ")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.remove_permanently_session_dialog = false;
            }
          }
        }, [_vm._v(" No ")]), _c('v-btn', {
          attrs: {
            "color": "red darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.remove_permanently_session_dialog = false;

              _vm.permanentRemoveSession(item.id);
            }
          }
        }, [_vm._v(" Yes ")])], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1), _c('div', {
          staticClass: "cursor-pointer mt-2"
        }, [_vm._v(_vm._s(item.id))])];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm.selected ? _c('v-simple-table', {
    staticClass: "mx-2",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v(" Trial name ")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v(" Status ")])])]), _c('tbody', _vm._l(_vm.trialsMapped, function (trial) {
          return _c('tr', {
            key: trial.id
          }, [_c('td', [_c('div', {
            staticClass: "float-right"
          }, [trial.trashed ? _c('v-menu', {
            attrs: {
              "offset-y": ""
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref5) {
                var on = _ref5.on,
                    attrs = _ref5.attrs;
                return [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "icon": "",
                    "dark": ""
                  }
                }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-menu")])], 1)];
              }
            }], null, true),
            model: {
              value: trial.isMenuOpen,
              callback: function ($$v) {
                _vm.$set(trial, "isMenuOpen", $$v);
              },
              expression: "trial.isMenuOpen"
            }
          }, [_c('v-list', [_c('v-dialog', {
            directives: [{
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.clickOutsideDialogTrialHideMenu,
              expression: "clickOutsideDialogTrialHideMenu"
            }],
            attrs: {
              "max-width": "500"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref6) {
                var on = _ref6.on;
                return [_c('v-list-item', _vm._g({
                  attrs: {
                    "link": ""
                  }
                }, on), [_c('v-list-item-title', [_vm._v("Restore")])], 1)];
              }
            }], null, true),
            model: {
              value: _vm.restore_trial_dialog,
              callback: function ($$v) {
                _vm.restore_trial_dialog = $$v;
              },
              expression: "restore_trial_dialog"
            }
          }, [_c('v-card', [_c('v-card-text', {
            staticClass: "pt-4"
          }, [_c('v-row', {
            staticClass: "m-0"
          }, [_c('v-col', {
            attrs: {
              "cols": "2"
            }
          }, [_c('v-icon', {
            attrs: {
              "x-large": "",
              "color": "green"
            }
          }, [_vm._v("mdi-undo-variant")])], 1), _c('v-col', {
            attrs: {
              "cols": "10"
            }
          }, [_c('p', [_vm._v(" Do you want to restore trial " + _vm._s(trial.name) + "? ")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
            attrs: {
              "color": "blue darken-1",
              "text": ""
            },
            on: {
              "click": function ($event) {
                trial.isMenuOpen = false;
                _vm.restore_trial_dialog = false;
              }
            }
          }, [_vm._v(" No ")]), _c('v-btn', {
            attrs: {
              "color": "green darken-1",
              "text": ""
            },
            on: {
              "click": function ($event) {
                trial.isMenuOpen = false;
                _vm.restore_trial_dialog = false;

                _vm.restoreTrial(trial);
              }
            }
          }, [_vm._v(" Yes ")])], 1)], 1)], 1), _c('v-dialog', {
            directives: [{
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.clickOutsideDialogTrialHideMenu,
              expression: "clickOutsideDialogTrialHideMenu"
            }],
            attrs: {
              "max-width": "500"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref7) {
                var on = _ref7.on;
                return [_c('v-list-item', _vm._g({
                  attrs: {
                    "link": ""
                  }
                }, on), [_c('v-list-item-title', [_vm._v("Delete permanently")])], 1)];
              }
            }], null, true),
            model: {
              value: _vm.remove_permanently_trial_dialog,
              callback: function ($$v) {
                _vm.remove_permanently_trial_dialog = $$v;
              },
              expression: "remove_permanently_trial_dialog"
            }
          }, [_c('v-card', [_c('v-card-text', {
            staticClass: "pt-4"
          }, [_c('v-row', {
            staticClass: "m-0"
          }, [_c('v-col', {
            attrs: {
              "cols": "2"
            }
          }, [_c('v-icon', {
            attrs: {
              "x-large": "",
              "color": "red"
            }
          }, [_vm._v("mdi-close-circle")])], 1), _c('v-col', {
            attrs: {
              "cols": "10"
            }
          }, [_c('p', [_vm._v(" Do you want to "), _c('strong', [_vm._v("permanently")]), _vm._v(" remove trial " + _vm._s(trial.name) + "? ")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
            attrs: {
              "color": "blue darken-1",
              "text": ""
            },
            on: {
              "click": function ($event) {
                trial.isMenuOpen = false;
                _vm.remove_permanently_trial_dialog = false;
              }
            }
          }, [_vm._v(" No ")]), _c('v-btn', {
            attrs: {
              "color": "red darken-1",
              "text": ""
            },
            on: {
              "click": function ($event) {
                trial.isMenuOpen = false;
                _vm.remove_permanently_trial_dialog = false;

                _vm.permanentRemoveTrial(trial);
              }
            }
          }, [_vm._v(" Yes ")])], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1), _c('div', {
            staticClass: "mt-2"
          }, [_vm._v(_vm._s(trial.name))])]), _c('td', [_vm._v(_vm._s(trial.status))])]);
        }), 0)];
      },
      proxy: true
    }], null, false, 2428997563)
  }) : _vm._e()], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500"
    },
    model: {
      value: _vm.empty_bin_dialog,
      callback: function ($$v) {
        _vm.empty_bin_dialog = $$v;
      },
      expression: "empty_bin_dialog"
    }
  }, [_c('v-card', [_c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-row', {
    staticClass: "m-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": "",
      "color": "red"
    }
  }, [_vm._v("mdi-close-circle")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('p', [_vm._v(" Do you want to "), _c('strong', [_vm._v("permanently")]), _vm._v(" empty the Recycle Bin? ")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.empty_bin_dialog = false;
      }
    }
  }, [_vm._v(" No ")]), _c('v-btn', {
    attrs: {
      "color": "red darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.empty_bin_dialog = false;

        _vm.emptyBin();
      }
    }
  }, [_vm._v(" Yes ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }