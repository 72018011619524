var render = function () {
  var _vm$session_selected, _vm$session_selected2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chart-page d-flex flex-column",
    attrs: {
      "id": "body"
    }
  }, [_vm.show_dashboard ? _c('div', {
    staticClass: "dashboard-body d-flex"
  }, _vm._l(_vm.dashboard.layout, function (column, column_name, column_idx) {
    return _c('div', {
      key: column_idx,
      class: column.classes
    }, _vm._l(column.widgets, function (block) {
      return _c('div', {
        key: block._id,
        class: block.classes
      }, [_c(block.component, {
        tag: "component",
        attrs: {
          "block": block,
          "result": _vm.result,
          "trialID": _vm.trial_selected.id,
          "timePosition": _vm.time_position
        },
        on: {
          "changeTimePosition": _vm.captureTimePosition
        }
      })], 1);
    }), 0);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "pa-2 fixed-button fixed-button-to-left",
    attrs: {
      "id": "button-left"
    }
  }, [_c('v-btn', {
    on: {
      "click": _vm.leftMenu
    }
  }, [_vm._v(" Data ")])], 1), _c('v-card', {
    staticClass: "sidebar left-sidebar"
  }, [_c('div', {
    staticClass: "pa-4 left-menu-close-button"
  }, [_c('v-btn', {
    attrs: {
      "width": "64px"
    },
    on: {
      "click": _vm.leftMenu
    }
  }, [_vm._v(" ✖ ")])], 1), _vm.dashboard.data ? _c('v-card-text', {
    attrs: {
      "height": "100%"
    }
  }, [_c('v-toolbar-title', {
    staticClass: "text-center"
  }, [_vm._v("Data Menu")]), _c('v-subheader', {
    staticClass: "subheader-bold"
  }), _c('div', {
    staticClass: "left d-flex flex-column pa-2"
  }, [_c('v-card', [_c('v-card-text', [_c('v-select', {
    attrs: {
      "item-value": "id",
      "item-text": "name",
      "items": _vm.dashboard.data.subjects,
      "label": "Select subject",
      "outlined": "",
      "dense": "",
      "return-object": ""
    },
    model: {
      value: _vm.subject_selected,
      callback: function ($$v) {
        _vm.subject_selected = $$v;
      },
      expression: "subject_selected"
    }
  }), _c('v-select', {
    attrs: {
      "item-value": "id",
      "item-text": "id",
      "items": _vm.filteredSessions,
      "disabled": !_vm.subject_selected,
      "label": "Select session",
      "outlined": "",
      "dense": "",
      "return-object": ""
    },
    model: {
      value: _vm.session_selected,
      callback: function ($$v) {
        _vm.session_selected = $$v;
      },
      expression: "session_selected"
    }
  }), _c('v-select', {
    attrs: {
      "item-value": "id",
      "item-text": "name",
      "items": _vm.filteredTrials,
      "disabled": !_vm.session_selected,
      "label": "Select trial",
      "outlined": "",
      "dense": "",
      "return-object": ""
    },
    model: {
      value: _vm.trial_selected,
      callback: function ($$v) {
        _vm.trial_selected = $$v;
      },
      expression: "trial_selected"
    }
  })], 1), _c('div', {
    staticClass: "left d-flex flex-column pa-2"
  }, [_vm.loggedIn ? _c('div', {
    staticClass: "left d-flex flex-column"
  }, [_c('v-dialog', {
    attrs: {
      "width": "500"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.loggedIn && _vm.trial_selected,
            expression: "loggedIn && trial_selected"
          }],
          staticClass: "mt-4 w-100",
          attrs: {
            "small": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Share analysis publicly")])];
      }
    }], null, false, 1716782271),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" Share analysis publicly ")]), _c('v-card-text', [!((_vm$session_selected = _vm.session_selected) !== null && _vm$session_selected !== void 0 && _vm$session_selected.public) ? _c('div', [_c('v-alert', {
    attrs: {
      "color": "error",
      "icon": "$error"
    }
  }, [_vm._v("To make your analysis public, you need to make your session public")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.setSessionPublic
    }
  }, [_vm._v("Make session public")])], 1) : _vm._e(), (_vm$session_selected2 = _vm.session_selected) !== null && _vm$session_selected2 !== void 0 && _vm$session_selected2.public ? _c('v-container', [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Share on")]), _c('ShareNetwork', {
    staticClass: "mr-2",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "network": "facebook",
      "url": _vm.dashboardUrl,
      "title": "OpenCap session"
    }
  }, [_c('v-btn', [_c('v-icon', {
    attrs: {
      "aria-hidden": "false"
    }
  }, [_vm._v("mdi-facebook")]), _vm._v("  Facebook")], 1)], 1), _c('ShareNetwork', {
    staticClass: "mr-2",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "network": "twitter",
      "url": _vm.dashboardUrl,
      "title": "OpenCap session"
    }
  }, [_c('v-btn', [_c('v-icon', {
    attrs: {
      "aria-hidden": "false"
    }
  }, [_vm._v("mdi-twitter")]), _vm._v("  Twitter")], 1)], 1), _c('ShareNetwork', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "network": "linkedin",
      "url": _vm.dashboardUrl,
      "title": "OpenCap session"
    }
  }, [_c('v-btn', [_c('v-icon', {
    attrs: {
      "aria-hidden": "false"
    }
  }, [_vm._v("mdi-linkedin")]), _vm._v("  LinkedIn")], 1)], 1), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Alternatively, copy this link",
      "readonly": ""
    },
    model: {
      value: _vm.dashboardUrl,
      callback: function ($$v) {
        _vm.dashboardUrl = $$v;
      },
      expression: "dashboardUrl"
    }
  })], 1) : _vm._e()], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Close ")])], 1)], 1)], 1), _c('v-btn', {
    staticClass: "w-100 mt-4",
    attrs: {
      "to": {
        name: 'SelectSession'
      }
    }
  }, [_vm._v("Back to session list ")]), _c('v-btn', {
    staticClass: "w-100 mt-4",
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'Session',
          params: {
            id: _vm.session_selected.id
          }
        });
      }
    }
  }, [_vm._v(" Go to Visualizer ")])], 1) : _vm._e()])], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }