var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-space-around"
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "disabled": _vm.frame === 0 || _vm.disabled || _vm.playing
    },
    on: {
      "click": function ($event) {
        _vm.frame = 0;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-skip-backward")])], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "disabled": _vm.frame === 0 || _vm.disabled || _vm.playing
    },
    on: {
      "click": function ($event) {
        _vm.frame--;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-step-backward")])], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.$emit(_vm.playing ? 'pause' : 'play');
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.playing ? 'mdi-pause' : 'mdi-play'))])], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "disabled": _vm.frame === _vm.maxFrame || _vm.disabled || _vm.playing
    },
    on: {
      "click": function ($event) {
        _vm.frame++;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-step-forward")])], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "disabled": _vm.frame === _vm.maxFrame || _vm.disabled || _vm.playing
    },
    on: {
      "click": function ($event) {
        _vm.frame = _vm.maxFrame;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-skip-forward")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }