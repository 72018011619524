var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "example-image d-flex flex-column"
  }, [_c('div', {
    staticClass: "image",
    style: _vm.getStyle
  }, [_c('div', {
    staticClass: "fade"
  }, [_c('IconButton', {
    attrs: {
      "role": _vm.good ? 'check' : 'cross'
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }