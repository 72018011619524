var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-center status",
    class: ("st-" + (_vm.value.status)),
    on: {
      "click": _vm.onClick
    }
  }, [_c('div', {
    staticClass: "mr-2 flex-shrink-0"
  }), _c('span', {
    staticClass: "overflow-text",
    attrs: {
      "title": _vm.value.name
    }
  }, [_vm._v(_vm._s(_vm.value.name))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }