var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chart-page d-flex flex-column",
    attrs: {
      "id": "body"
    }
  }, [_c('div', {
    staticClass: "content-chart"
  }, [_vm._m(0), _c('LineChartGenerator', {
    ref: "chartRef",
    staticStyle: {
      "position": "relative",
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "id": "chart",
      "chart-options": _vm.chartOptions,
      "chart-data": _vm.chartData
    }
  })], 1), _c('div', {
    staticClass: "pa-2 fixed-button fixed-button-to-left",
    attrs: {
      "id": "button-left"
    }
  }, [_c('v-btn', {
    on: {
      "click": _vm.leftMenu
    }
  }, [_vm._v(" Data ")])], 1), _c('div', {
    staticClass: "pa-2 fixed-button fixed-button-to-right",
    attrs: {
      "id": "button-right"
    }
  }, [_c('v-btn', {
    on: {
      "click": _vm.rightMenu
    }
  }, [_vm._v(" Options ")])], 1), _c('v-card', {
    staticClass: "sidebar left-sidebar"
  }, [_c('div', {
    staticClass: "pa-4 left-menu-close-button"
  }, [_c('v-btn', {
    attrs: {
      "width": "64px"
    },
    on: {
      "click": _vm.leftMenu
    }
  }, [_vm._v(" ✖ ")])], 1), _c('v-card-text', {
    attrs: {
      "height": "100%"
    }
  }, [_c('v-toolbar-title', {
    staticClass: "text-center"
  }, [_vm._v("Data Menu")]), _c('v-subheader', {
    staticClass: "subheader-bold"
  }), _c('div', {
    staticClass: "left d-flex flex-column pa-2"
  }, [_vm.session_owned ? _c('div', [_c('v-select', {
    attrs: {
      "items": _vm.sessionsIds,
      "label": "Select session",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": _vm.onSessionSelected
    },
    model: {
      value: _vm.session_selected,
      callback: function ($$v) {
        _vm.session_selected = $$v;
      },
      expression: "session_selected"
    }
  })], 1) : _c('div', [_c('p', [_vm._v(" This is a public session. To load your sessions, launch the dashboard from your session list. ")])]), _c('v-select', {
    attrs: {
      "items": _vm.trial_names,
      "label": "Select trial",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": _vm.onTrialSelected
    },
    model: {
      value: _vm.trial_selected,
      callback: function ($$v) {
        _vm.trial_selected = $$v;
      },
      expression: "trial_selected"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.x_quantities,
      "label": "X Quantity",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": _vm.onXQuantitySelected
    },
    model: {
      value: _vm.x_quantity_selected,
      callback: function ($$v) {
        _vm.x_quantity_selected = $$v;
      },
      expression: "x_quantity_selected"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.y_quantities,
      "label": "Y Quantities",
      "multiple": "",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": _vm.onYQuantitySelected
    }
  })], 1)], 1), _c('div', {
    staticClass: "left d-flex flex-column pa-2"
  }, [_c('v-btn', {
    staticClass: "w-100",
    on: {
      "click": _vm.onChartDownload
    }
  }, [_vm._v(" Download Chart ")]), _c('v-btn', {
    staticClass: "w-100 mt-4",
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'Session',
          params: {
            id: _vm.current_session_id
          }
        });
      }
    }
  }, [_vm._v(" Go to Visualizer ")]), _vm.loggedIn ? _c('div', {
    staticClass: "left d-flex flex-column"
  }, [_c('v-btn', {
    staticClass: "w-100 mt-4",
    attrs: {
      "to": {
        name: 'SelectSession'
      }
    }
  }, [_vm._v("Back to session list ")])], 1) : _vm._e()], 1)], 1), _c('v-card', {
    staticClass: "sidebar right-sidebar"
  }, [_c('div', {
    staticClass: "pa-4 right-menu-close-button"
  }, [_c('v-btn', {
    attrs: {
      "width": "64px"
    },
    on: {
      "click": _vm.rightMenu
    }
  }, [_vm._v(" ✖ ")])], 1), _c('v-card-text', [_c('v-toolbar-title', {
    staticClass: "text-center"
  }, [_vm._v("Options Menu")]), _c('v-subheader', {
    staticClass: "subheader-bold"
  }), _c('div', {
    staticClass: "left d-flex flex-column pa-2"
  }, [_c('v-text-field', {
    attrs: {
      "label": "Title",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.chartOptions.plugins.title.text,
      callback: function ($$v) {
        _vm.$set(_vm.chartOptions.plugins.title, "text", $$v);
      },
      expression: "chartOptions.plugins.title.text"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Subtitle",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.chartOptions.plugins.subtitle.text,
      callback: function ($$v) {
        _vm.$set(_vm.chartOptions.plugins.subtitle, "text", $$v);
      },
      expression: "chartOptions.plugins.subtitle.text"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "H. Axis Title",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.chartOptions.scales.x.title.text,
      callback: function ($$v) {
        _vm.$set(_vm.chartOptions.scales.x.title, "text", $$v);
      },
      expression: "chartOptions.scales.x.title.text"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "V. Axis Title",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.chartOptions.scales.y.title.text,
      callback: function ($$v) {
        _vm.$set(_vm.chartOptions.scales.y.title, "text", $$v);
      },
      expression: "chartOptions.scales.y.title.text"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Line Width",
      "outlined": "",
      "dense": "",
      "type": "number"
    },
    on: {
      "input": _vm.drawChart
    },
    model: {
      value: _vm.chart_line_width,
      callback: function ($$v) {
        _vm.chart_line_width = $$v;
      },
      expression: "chart_line_width"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.chart_point_style_options,
      "label": "Point Style",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": _vm.drawChart
    },
    model: {
      value: _vm.chart_point_style,
      callback: function ($$v) {
        _vm.chart_point_style = $$v;
      },
      expression: "chart_point_style"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Point Size",
      "outlined": "",
      "dense": "",
      "type": "number"
    },
    on: {
      "input": _vm.drawChart
    },
    model: {
      value: _vm.chart_point_radius,
      callback: function ($$v) {
        _vm.chart_point_radius = $$v;
      },
      expression: "chart_point_radius"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.chart_legend_position,
      "label": "Legend Position",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": _vm.placeholderFunction
    },
    model: {
      value: _vm.chartOptions.plugins.legend.position,
      callback: function ($$v) {
        _vm.$set(_vm.chartOptions.plugins.legend, "position", $$v);
      },
      expression: "chartOptions.plugins.legend.position"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.chart_legend_alignment,
      "label": "Legend Alignment",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": _vm.placeholderFunction
    },
    model: {
      value: _vm.chartOptions.plugins.legend.align,
      callback: function ($$v) {
        _vm.$set(_vm.chartOptions.plugins.legend, "align", $$v);
      },
      expression: "chartOptions.plugins.legend.align"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.chart_color_scales_options,
      "label": "Color Scale",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": _vm.drawChart
    },
    model: {
      value: _vm.chart_color_scales_selected,
      callback: function ($$v) {
        _vm.chart_color_scales_selected = $$v;
      },
      expression: "chart_color_scales_selected"
    }
  }), _c('v-btn', {
    staticClass: "w-100",
    on: {
      "click": _vm.onResetZoom
    }
  }, [_vm._v(" Reset Zoom ")]), _c('icon-tooltip', {
    attrs: {
      "tooltip-text": "\n              Zoom instructions:</br>\n               - <b>Zoom</b>: Click and Drag over a zone.</br>\n               - <b>Move</b>: CTRL + Click and move mouse.</br>\n               - <b>Zoom on X</b>: Mouse wheel on X axis.</br>\n               - <b>Zoom on Y</b>: Mouse wheel on Y axis.</br>\n          ",
      "iconClass": "fas fa-question-circle"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "relative",
      "width": "100%",
      "height": "100%",
      "display": "none"
    },
    attrs: {
      "id": "spinner-layer"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "absolute",
      "top": "50%",
      "left": "50%",
      "transform": "translate(-50%, -50%)",
      "text-align": "center"
    }
  }, [_c('div', {
    staticClass: "spinner"
  })]), _c('div', {
    staticStyle: {
      "position": "absolute",
      "top": "40%",
      "left": "50%",
      "transform": "translate(-50%, -50%)",
      "text-align": "center",
      "color": "black"
    }
  }, [_c('h3', [_vm._v("Loading Chart")])])]);
}]

export { render, staticRenderFns }