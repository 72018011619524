var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "video-player d-flex"
  }, [_c('div', {
    staticClass: "viewer flex-grow-1"
  }, [_vm.trial ? _c('div', {
    staticClass: "d-flex flex-column h-100"
  }, [_c('div', {
    ref: "mocap",
    staticClass: "flex-grow-1",
    attrs: {
      "id": "mocap"
    }
  }), !_vm.videoControlsDisabled ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "wrap",
      "align-items": "center"
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "flex": "0.1",
      "margin-right": "5px"
    },
    attrs: {
      "label": "Time (s)",
      "type": "number",
      "step": 0.01,
      "value": _vm.time,
      "dark": ""
    },
    on: {
      "input": _vm.onChangeTime
    }
  }), _c('v-slider', {
    staticClass: "mb-2",
    staticStyle: {
      "flex": "1"
    },
    attrs: {
      "value": _vm.frame,
      "min": _vm.timeToFrame(_vm.timeStart),
      "max": _vm.timeToFrame(_vm.timeEnd),
      "hide-details": ""
    },
    on: {
      "input": _vm.onNavigate
    }
  })], 1) : _vm._e()]) : _vm.trialLoading ? _c('div', {
    staticClass: "flex-grow-1 d-flex align-center justify-center"
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "grey",
      "size": "30",
      "width": "4"
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "right d-flex flex-column"
  }, [_c('div', {
    staticClass: "videos flex-grow-1 d-flex flex-column"
  }, _vm._l(_vm.videos, function (video, index) {
    return _c('video', {
      key: ("video-" + index),
      ref: ("video-" + index),
      refInFor: true,
      attrs: {
        "muted": "",
        "playsinline": "",
        "src": video.media,
        "crossorigin": "anonymous"
      },
      domProps: {
        "muted": true
      },
      on: {
        "loadedmetadata": function ($event) {
          return _vm.onVideoLoadMetadata(index);
        },
        "timeupdate": function ($event) {
          return _vm.onVideoTimeUpdate(index);
        },
        "ended": function ($event) {
          return _vm.onVideoEnded(index);
        }
      }
    });
  }), 0), _c('SpeedControl', {
    model: {
      value: _vm.playSpeed,
      callback: function ($$v) {
        _vm.playSpeed = $$v;
      },
      expression: "playSpeed"
    }
  }), _c('VideoNavigation', {
    staticClass: "mb-2",
    attrs: {
      "playing": _vm.playing,
      "value": _vm.frame,
      "maxFrame": _vm.frames.length - 1,
      "disabled": _vm.videoControlsDisabled
    },
    on: {
      "play": function ($event) {
        return _vm.togglePlay(true);
      },
      "pause": function ($event) {
        return _vm.togglePlay(false);
      },
      "input": _vm.onNavigate
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }