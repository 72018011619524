var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MainLayout', {
    attrs: {
      "rightButton": "Next",
      "column": "",
      "step": 1,
      "rightDisabled": _vm.loading
    },
    on: {
      "right": _vm.onNext
    }
  }, [_c('v-card', {
    staticClass: "flex-grow-1 d-flex flex-column justify-center"
  }, [_c('v-card-text', {
    staticClass: "d-flex flex-column align-center"
  }, [_c('div', {
    staticClass: "d-flex step-1 align-center flex-wrap"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-grow-1 justify-space-between my-1"
  }, [_c('h1', {
    staticClass: "my-4"
  }, [_vm._v("1. Open the OpenCap app on your phone")]), _c('h1', {
    staticClass: "my-4"
  }, [_vm._v("2. Scan the QR code")]), _c('h1', {
    staticClass: "my-4"
  }, [_vm._v("3. Mount your phone vertically or horizontally (unlock portrait orientation) on a tripod")]), _c('h1', {
    staticClass: "my-4"
  }, [_vm._v("4. Position the tripod and camera to capture the volume of interest")]), _c('h1', {
    staticClass: "my-4"
  }, [_vm._v("5. Repeat 1-4 for all phones you want to connect")]), _c('h1', {
    staticClass: "my-4"
  }, [_vm._v("6. Have the person practice the activity and verify that they are fully in view of at least 2 cameras")])]), _c('div', {
    staticClass: "image-container qr-container d-flex align-center justify-center my-1"
  }, [_vm.loading ? _c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "grey",
      "size": "32",
      "width": "5"
    }
  }) : _c('img', {
    staticClass: "w-100 h-100",
    attrs: {
      "src": _vm.session.qrcode
    }
  })], 1)])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }