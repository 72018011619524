var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "scalar-value-wrapper"
  }, [_vm._l(_vm.sortedInfoText, function (row, row_idx) {
    return _c('div', {
      key: row_idx,
      class: row.classes
    }, [_c('div', {
      staticClass: "info-label"
    }, [_vm._v(_vm._s(row.label))]), _c('div', {
      staticClass: "info-text"
    }, [_vm._v(_vm._s(row.text))])]);
  }), _vm._l(_vm.sortedMetrics, function (row, row_idx) {
    return _c('div', {
      key: row_idx,
      class: row.classes
    }, [_c('div', {
      staticClass: "label-text"
    }, [_vm._v(_vm._s(row.label))]), _c('div', {
      staticClass: "scalar-text"
    }, [_vm._v(_vm._s(row.value) + " ± " + _vm._s(row.std))])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }