var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MainLayout', {
    attrs: {
      "column": "",
      "leftButton": "Back",
      "rightButton": "Calibrate",
      "step": 2,
      "rightDisabled": _vm.busy
    },
    on: {
      "left": function ($event) {
        return _vm.$router.push(("/" + (_vm.session.id) + "/connect-devices"));
      },
      "right": _vm.onNext
    }
  }, [_c('v-card', {
    staticClass: "step-2-1"
  }, [_c('v-card-text', {
    staticClass: "d-flex align-center"
  }, [_c('p', {
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm._v(_vm._s(_vm.n_videos_uploaded) + " of " + _vm._s(_vm.n_cameras_connected) + " videos uploaded.")])])], 1), _c('v-card', {
    staticClass: "step-2-2 mt-4 flex-grow-1"
  }, [_c('v-card-title', {
    staticClass: "justify-center"
  }, [_vm._v(" Place a checkerboard in the scene ")]), _c('v-card-text', {
    staticClass: "d-flex align-center"
  }, [_c('ul', {
    staticClass: "flex-grow-1"
  }, [_c('li', [_vm._v("It should be visible by all cameras (nothing in the way of cameras' view when hitting Calibrate)")]), _c('li', [_vm._v("It should be horizontal (longer side on the floor)")]), _c('li', [_vm._v("It should be perpendicular to the floor (not lying on the floor)")])]), _c('div', {
    staticClass: "image-container pa-3"
  }, [_c('img', {
    attrs: {
      "src": "/images/checkerboard-placement.png"
    }
  })])])], 1), _c('v-card', {
    staticClass: "step-2-2 mt-4 flex-grow-1"
  }, [_c('v-card-title', {
    staticClass: "justify-center"
  }, [_vm._v(" Provide the checkerboard details ")]), _c('v-card-text', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "d-flex flex-grow-1 align-center inputs"
  }, [_c('v-text-field', {
    staticClass: "mr-3",
    attrs: {
      "label": "Rows"
    },
    model: {
      value: _vm.rows,
      callback: function ($$v) {
        _vm.rows = $$v;
      },
      expression: "rows"
    }
  }), _c('v-text-field', {
    staticClass: "mr-3",
    attrs: {
      "label": "Columns"
    },
    model: {
      value: _vm.cols,
      callback: function ($$v) {
        _vm.cols = $$v;
      },
      expression: "cols"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Square size (mm)"
    },
    model: {
      value: _vm.squareSize,
      callback: function ($$v) {
        _vm.squareSize = $$v;
      },
      expression: "squareSize"
    }
  })], 1), _c('div', {
    staticClass: "image-container pa-3"
  }, [_c('img', {
    attrs: {
      "src": "/images/checkerboard_45.png"
    }
  })])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }