var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-layout d-flex flex-column pa-4",
    class: {
      'fixed-height': _vm.fixedHeight
    }
  }, [_c('div', {
    staticClass: "content-wrapper flex-grow-1 d-flex",
    class: {
      'flex-column': _vm.column
    }
  }, [_vm._t("default")], 2), _c('div', {
    staticClass: "navigation d-flex justify-space-between align-center mt-5 w-100"
  }, [_c('div', {
    staticClass: "slot"
  }, [_vm.leftButton ? _c('v-btn', {
    on: {
      "click": function ($event) {
        return _vm.$emit('left');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.leftButton) + " ")]) : _vm._t("left")], 2), _c('div', {
    staticClass: "slot d-flex justify-end"
  }, [_vm.rightButton ? _c('v-btn', {
    attrs: {
      "disabled": _vm.rightDisabled || _vm.rightSpinner
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('right');
      }
    }
  }, [_vm.rightSpinner ? _c('v-progress-circular', {
    staticClass: "mr-2",
    attrs: {
      "indeterminate": "",
      "color": "grey",
      "size": "14",
      "width": "2"
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.rightButton) + " ")], 1) : _vm._t("right")], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }