var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showSessionQR ? _c('div', [_c('v-btn', {
    staticClass: "text-center",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.loadQRCode
    }
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Reconnect phone")]), _c('v-icon', [_vm._v("mdi-qrcode")])], 1), _c('v-dialog', {
    attrs: {
      "activator": "parent",
      "width": "auto"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-text', [_vm.loading ? _c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "grey",
      "size": "32",
      "width": "5"
    }
  }) : _c('img', {
    staticClass: "w-100 h-100",
    attrs: {
      "src": _vm.session.qrcode
    }
  })], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "block": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }