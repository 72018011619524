var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tooltip-container mt-2 mb-2"
  }, [_c('span', {
    staticClass: "tooltip-icon",
    on: {
      "mouseover": function ($event) {
        _vm.showTooltip = true;
      },
      "mouseleave": function ($event) {
        _vm.showTooltip = false;
      }
    }
  }, [_c('i', {
    class: _vm.iconClass
  })]), _vm.showTooltip ? _c('div', {
    staticClass: "tooltip",
    domProps: {
      "innerHTML": _vm._s(_vm.tooltipText)
    }
  }) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }