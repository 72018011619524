var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    style: {
      background: _vm.$vuetify.theme.themes.dark.background
    }
  }, [_c('v-app-bar', {
    attrs: {
      "app": "",
      "dark": ""
    }
  }, [_vm.showLink ? _c('router-link', {
    attrs: {
      "to": {
        name: 'SelectSession'
      }
    }
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "height": "59px",
      "src": "/images/opencap-logo-dark.png"
    }
  })]) : _c('router-link', {
    attrs: {
      "to": {
        name: '#'
      }
    }
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "height": "59px",
      "src": "/images/opencap-logo-dark.png"
    }
  })]), _c('v-spacer'), _c('QRCodeDialog'), _vm.verified ? _c('profile-dropdown', {
    staticClass: "ml-8"
  }) : _vm._e()], 1), _c('v-main', [_c('router-view')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }