var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('div', [_vm._v("Session " + _vm._s(_vm.session_id))])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('div', {
    attrs: {
      "id": "mocap"
    }
  }), _c('div', [_vm._v(" Use mouse to control the 3D view. ")])]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm.trial && _vm.state == 'ready' ? _c('div', {
    attrs: {
      "id": "videos"
    }
  }, _vm._l(_vm.videos, function (res) {
    return _c('video', {
      key: res.id,
      attrs: {
        "autoplay": "true",
        "muted": "",
        "playsinline": "",
        "id": res.id,
        "src": res.media,
        "controls": "true",
        "crossorigin": "anonymous"
      },
      domProps: {
        "muted": true
      }
    });
  }), 0) : _vm._e()])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }